<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-07-09 16:39:02
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-07-10 16:02:56
-->
<template>
    <div class="setupDocument">
        <div class="sm-title">
            <div class="mt-title">设置文档</div>
            <!-- <div class="mt-add">
                <div class="add" @click="clickAddBtn">创建套餐</div>
            </div> -->
        </div>
        <div class="conetnt-wrap">
            <div style="padding-bottom: 20px">
                <el-button @click="clickAdd" type="primary" >添加标题</el-button>
            </div>
            <el-table
                :data="tableData"
                style="width: 100%;"
                row-key="id"
                border
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column prop="sort" label="排序" width="100"></el-table-column>
                <el-table-column prop="name" label="标题"></el-table-column>
                <!-- <el-table-column label="内容">
                    <template>
                        <div>
                            <div v-if="scope.row.superior_id != 0">{{scope.row.content}}</div>
                        </div>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作"  width="250">
                    <template slot-scope="scope">
                        <div>
                            <el-button @click="clickAddMini(scope.row.id)" size="mini" type="primary">添加</el-button>
                            <el-button @click="clickEdit(scope.row)" size="mini" type="warning" plain>编辑</el-button>
                            <el-button @click="clickDel(scope.row)" size="mini" type="danger">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog
            :visible.sync="isBigShow"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="900px">
            <div class="class-wrap">
                <div class="cw-title">{{rowInfo.id?'编辑内容':'设置内容'}}</div>
                <div class="cw-list">
                    <div class="item">
                        <div class="tip">标题</div>
                        <el-input v-model="info.name" placeholder="请输入标题"></el-input>
                    </div>
                    <div class="item">
                        <div class="tip">内容</div>
                        <vue-ueditor-wrap v-model="info.content" :config="ue_config"></vue-ueditor-wrap>
                    </div>
                    <div class="item">
                        <div class="tip">排序</div>
                        <el-input-number v-model="info.sort" :min="0" :max="1000" placeholder="请输入排序"></el-input-number>
                    </div>
                </div>
                <div class="btn-wrap">
                    <div class="cw-btn quxiao" @click="clickClose">取消</div>
                    <div v-if="miniId" class="cw-btn" @click="clickSaveMini">保存</div>
                    <div v-else>
                        <div v-if="rowInfo.id" class="cw-btn" @click="clickSave">保存</div>
                        <div v-else class="cw-btn" @click="clickAddMain">确定</div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">确定删除{{rowInfo.name}}内容?</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="isDel = false">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
import VueUeditorWrap from 'vue-ueditor-wrap'; //编辑器
import {htmlDecode,htmlEncode} from "@/utils/util.js";

export default {
    mixins:[pageMixin],
    components: {
        VueUeditorWrap
    },
    data () {
        return {
            pageMixin_index:'setupDocument',
            tableData: [],
            isBigShow: false,
            isDel: false,
            info: {
                name: '',
                sort: '',
                content: '',
            },
            rowInfo: {},
            ue_config: {
                zIndex: 9999,
                // 编辑器不自动被内容撑高
                autoHeightEnabled: false,
                // 初始容器高度
                initialFrameHeight: 260,
                // 初始容器宽度
                initialFrameWidth: '860',
                // 上传文件接口
                serverUrl: '/UEditor/js/config.json',
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: '/UEditor/'
            },
            miniId: ''
        }
    },
    mounted () {
        this.getHelpList()
    },
    methods: {
        // 添加小标题
        clickSaveMini(){
            if(this.info.name == ''){
                this.$message.error('请输入标题')
                return
            }
            this.isBigShow = false
            let params = {
                name: this.info.name,
                sort: this.info.sort,
                content: this.info.content,
                superior_id: this.miniId,
            }
            api.post('/service/system/help_add', params, (res) =>  {
                this.$message.success('已添加')
                this.info.name = '';
                this.info.content = '';
                this.info.sort = '';
                this.miniId = ''
                this.getHelpList()
            });
        },
        clickAddMini(id){
            this.miniId = id
            this.isBigShow = true;
        },
        // 确定删除
        clickConfirm(){
            this.isDel = false
            let params = {
                id: this.rowInfo.id,
            }
            api.post('/service/system/help_del', params, (res) =>  {
                this.$message.success('已删除')
                this.getHelpList()
            });
        },
        // 删除
        clickDel(row){
            this.isDel = true
            this.rowInfo = row
        },
        // 确定编辑
        clickSave(){
            if(this.info.name == ''){
                this.$message.error('请输入标题')
                return
            }
            this.isBigShow = false
            let params = {
                id: this.rowInfo.id,
                name: this.info.name,
                content: this.info.content,
                sort: this.info.sort,
            }
            api.post('/service/system/help_edit', params, (res) =>  {
                this.$message.success('已修改')
                this.info.name = '';
                this.info.content = '';
                this.info.sort = '';
                this.getHelpList()
            });
        },
        // 编辑
        clickEdit(row){
            this.rowInfo = row
            this.isBigShow = true
            this.info.name = row.name;
            this.info.sort = row.sort;
            this.info.content = row.content;
        },
        // 添加大标题
        clickAddMain(){
            if(this.info.name == ''){
                this.$message.error('请输入标题')
                return
            }
            this.isBigShow = false
            let params = {
                name: this.info.name,
                sort: this.info.sort,
                content: this.info.content,
                superior_id: 0,
            }
            api.post('/service/system/help_add', params, (res) =>  {
                this.$message.success('已添加')
                this.info.name = '';
                this.info.content = '';
                this.info.sort = '';
                this.getHelpList()
            });
        },
        clickClose(){
            this.isBigShow = false
            this.info.name = '';
            this.info.content = '';
            this.miniId = '';
            this.info.sort = '';
            this.rowInfo = {}
        },
        clickAdd(){
            this.rowInfo = {}
            this.isBigShow = true
        },
        // 获取文档数据
        getHelpList(){
            api.get('/service/system/get_help_list', {}, (res) =>  {
                this.tableData = res.data.list
            });
        },
    },
}
</script>

<style lang='scss'>
.setupDocument{
    .sm-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .mt-title{
            font-size: 24px;
            color: #101010;
        }
        .mt-add{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .add{
                width: 130px;
                height: 36px;
                border-radius: 6px;
                border: 1px solid #0068ff;
                font-size: 14px;
                color: #0068ff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .conetnt-wrap{
        padding: 20px;
        margin: 20px;
        background: #fff;
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            text-align: center;
            font-weight: bold;
            margin-top: -5px;
        }
        .cw-list{
            padding: 20px;
            .item{
                margin-bottom: 15px;
                .tip{
                    font-size: 15px;
                    color: #101010;
                    padding-bottom: 10px;
                }
                .avatar{
                    width: 100px;
                    height: 100px;
                    vertical-align: bottom;
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 20px;
            .cw-btn{
                width: 100px;
                height: 40px;
                border-radius: 4px;
                font-size: 15px;
                color: #fff;
                background: #0068ff;
                margin: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid #0068ff;
            }
            .quxiao{
                background: #fff;
                color: #0068ff;
            }
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }

}

</style>
